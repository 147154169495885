import React, { useState, useRef, useCallback, useEffect } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { GoFileDirectory, GoFile, GoReply } from 'react-icons/go';

import './style.scss';

function PathSelect(props) {
    const {
        defaultPth = '/',
        placeholder,
        caption = 'Select path',
        buttonText = 'Select',
        endpoint = '/readDir',
        onChange
    } = props;

    const [isShow, setIsShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('')
    const [pwd, setPwd] = useState(defaultPth || '');
    const [error, setError] = useState('');

    useEffect(() => {
        getFiles(defaultPth)
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(`${pwd}${selectedFile}`);
        }
    }, [pwd, selectedFile])

    const getFiles = useCallback((path) => {
        const body = { catalog: path };
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    setError(res.error)
                } else {
                    setPwd(res.pwd.replace('//', '/'));
                    setFiles(res.files);
                }
            })
            .catch((err) =>{
                console.log(err)
            })
    }, [setPwd, setFiles]);

    const handleSelectDir = useCallback((event) => {
        const dir = event.target.id;
        const type = event.currentTarget.dataset.filetype;
        if(type === 'file') {
            setSelectedFile(`/${event.currentTarget.dataset.file}`)
        } else {
            setError('')
            setSelectedFile('')
            getFiles(dir)
        }
    }, []);

    const handleOpen = useCallback(() => {
        setIsShow(!isShow)
    },[isShow, setIsShow]);

    const handleChanhePath = useCallback((event) => {
        setSelectedFile('');
        setPwd(event.target.value);
    },[isShow, setIsShow]);

    return (
        <div className="pathSelect">
            <span className='caption'>{caption}:</span>
            <InputGroup>
                <InputGroup.Text className='inputHelper'>
                    <Button className='button' variant='warning' onClick={handleOpen}>
                        {buttonText}
                    </Button>
                </InputGroup.Text>
                <FormControl
                    className='inputPath'
                    placeholder={placeholder}
                    value={`${pwd}${selectedFile}`}
                    onChange={handleChanhePath}
                />
            </InputGroup>
            {
                isShow && (
                    <div className='overlay'>
                        {
                            error && (
                                <span className='error'>Error: {error}</span>
                            )
                        }
                        <div className='file' id={`${pwd}/../`} onClick={handleSelectDir}><span className='fileType'><GoReply /></span>..</div>
                        {
                            files.map((file) => {
                                const fileKey = `${pwd}/${file.element}`;
                                return (
                                    <div className='file' data-file={file.element} data-filetype={file.type} key={fileKey} id={fileKey} onClick={handleSelectDir} ><span className='fileType'>{file.type === 'dir' ? <GoFileDirectory /> : <GoFile />}</span>{file.element}</div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    );
}

export default PathSelect;
