import React, { useEffect } from 'react';

import WorkerHeader from '../../components/workerHeader/index'
import RepositorySettings from '../../components/repositorySettings/index'

import './style.scss';

const CreateWorker = () => {

    return (
        <div className='createWorkerPage'>
            <WorkerHeader />
            <RepositorySettings />
        </div>
    );
}

export default CreateWorker;
