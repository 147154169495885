import React, { useCallback }  from 'react';
import { useHistory } from 'react-router-dom';
import { GoGear } from 'react-icons/go';

import './style.scss';

const  JobCard = (props) => {
    const { name, repoUrl, id } = props;
    const history = useHistory();

    const handleEdit = useCallback((event) => {
        event.stopPropagation();
        history.push(`/worker/${id}/edit`)
    }, [id, history]);

    const handleOpen = useCallback((event) => {
        event.stopPropagation();
        history.push(`/worker/${id}`)
    }, [id, history]);

    return (
        <div className="jobCardWrapper" onClick={handleOpen}>
            <div className='jobCardCaption' >
                <span>{name}</span>
                <span className='url'>{repoUrl}</span>
            </div>
            <GoGear onClick={handleEdit} className='editButton' />
        </div>
    );
}

export default JobCard;
