import React   from 'react';

import './style.scss';

const  Group = (props) => {
    const { children, title} = props;

    return (
        <div className="group">
            <span className='title'>{title}</span>
            {children}
        </div>
    );
}

export default Group;
