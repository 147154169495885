import React, { useEffect } from 'react';

import PathSelect from '../../components/pathSelect/index'

// import './App.scss';

function Main() {

    return (
        <div className="ManPage">
            <h1>hello</h1>
            <PathSelect defaultPth='/' onChange={(path) => {console.log(path)} } />
        </div>
    );
}

export default Main;
