import React, { useCallback, useState }   from 'react';
import { Form } from 'react-bootstrap';
import { GoPulse } from 'react-icons/go';

import Group from '../../components/group/index'

import './style.scss';

const RepositorySettings = (props) => {
    const { children, title, onChange } = props;

    const [isCheck, setIsCheck] = useState(true)

    return (
        <Group title='Repository settings' >
            <div className='repositorySettings'>
                <GoPulse className={`checkRepo ${isCheck && 'checked'}`} />
                <Form>
                    <Form.Group >
                        <Form.Label>Repository link (GitHub):</Form.Label>
                        <Form.Control size='sm' />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>User name:</Form.Label>
                        <Form.Control size='sm' />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Personal token:</Form.Label>
                        <Form.Control size='sm' as="textarea" rows={3} />
                    </Form.Group>
                </Form>
            </div>
        </Group>
    );
}

export default RepositorySettings;
