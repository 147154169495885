import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from  'react-router-dom';

import CreateWorker from './containers/CreateWorker/index';
import Main from './containers/Main/index';
import JobList from './components/jobList/index';

import MainContextProvider from './context/context'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <MainContextProvider>
          <Router>
              <div className='layout'>
                  <div className='leftMenu'>
                      <JobList />
                  </div>
                  <div className='content'>
                      <Switch>
                          <Route exact path='/' >
                              <Main />
                          </Route>
                          <Route exact path='/createWorker' >
                              <CreateWorker />
                          </Route>
                      </Switch>
                  </div>
              </div>
          </Router>
      </MainContextProvider>
  </React.StrictMode>
);
