import { useContext }  from 'react';

import { mainContext } from '../context/context';

const useCustomContext = (dataset) => {
    const [context, setContext] = useContext(mainContext);

    const setDataset = (data) => {
        setContext({
            ...context,
            [dataset]: data
        })
    }

    return [context[dataset], setDataset]
}

export default useCustomContext;