import React   from 'react';
import { Button } from 'react-bootstrap';

import './style.scss';

const  WorkerHeader = (props) => {

    return (
        <div className="workerHeader">
            <Button className='headerButton' variant="outline-danger">Отмна</Button>
            <Button className='headerButton' variant="outline-success">Сохранить</Button>
        </div>
    );
}

export default WorkerHeader;
