import { createContext, useState } from 'react';

export const mainContext = createContext();

const MainContextProvider = (props) => {
    const [context, setContext] = useState({
        settings: {},
        workers: []
    });

    return (
        // this is the provider providing state
        <mainContext.Provider value={[context, setContext]}>
            {props.children}
        </mainContext.Provider>
    );
};

export default MainContextProvider;