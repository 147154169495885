import React, { useEffect, useCallback }  from 'react';
import { GoPlus } from 'react-icons/go';
import { useHistory } from 'react-router-dom';

import useCustomContext from '../../hooks/useCustomContext';

import JobCard from '../jobCard/index';

import './style.scss';

const JobList = () => {
    const [workers, setWorkers] = useCustomContext('workers');
    const history = useHistory();

    const handleCreateWorker = useCallback(() => {
        history.push('/createWorker')
    }, [history])

    useEffect(() => {
        fetch('/data/getWorkers')
            .then((res) => res.json())
            .then((res) => setWorkers(res))
    }, []);

    return (
        <div className="jobListWrapper">
            <div className="jobListHeader">
                <span>Список воркеров</span>
                <GoPlus className='addButton' onClick={handleCreateWorker} />
            </div>
            <div className="jobList">
                {
                    workers.map((worker) => {
                        return <JobCard key={worker.id} id={worker.id} name={worker.name} repoUrl={worker.repoUrl} />
                    })
                }
            </div>
        </div>
    );
}

export default JobList;
